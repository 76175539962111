import type { PartialNull } from "@gigsmart/type-utils";
import React, {
  type ComponentProps,
  type ComponentType,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { BaseStepperMethods } from "./Stepper.base";
import { Step, getStepsFromChildren } from "./Stepper.components";
import type {
  IStepper,
  StepConfigProps,
  StepDescriptor,
  StepperLayoutProps,
  StepperMethods,
  StepperState
} from "./Stepper.types";

const Context = React.createContext<IStepper<unknown, unknown> | null>(null);
const Current = React.createContext<StepDescriptor<unknown> | null>(null);

const stepperStub: IStepper<unknown, unknown> = {
  index: 0,
  steps: [],
  data: {},
  hasPreviousStep: false,
  hasNextStep: true,
  setData: () => {},
  prevStep: () => {},
  nextStep: () => {},
  gotoStep: () => {},
  setOptions: () => {}
};
const currentStepStub: StepDescriptor<unknown> = {
  hidden: true,
  index: -1,
  normalizedIndex: 1,
  key: "unknown",
  options: {},
  render: () => null
};

export function useStepperV2<TData, T = unknown>(): IStepper<TData, T> {
  const stepper = useContext(Context);
  if (!stepper) {
    console.warn("Warning: Not in a Stepper");
    return stepperStub as IStepper<TData, T>;
  }
  return stepper as IStepper<TData, T>;
}

export function useCurrentStep<T = unknown>() {
  let current = useContext(Current);
  if (!current) {
    console.warn("Warning: No current step");
    current = currentStepStub;
  }
  return current as StepDescriptor<T>;
}

type StepperProps<TData, T, TLayout extends StepperLayoutProps<T>> = {
  testID: string;
  layout: ComponentType<TLayout>;
  initialData: TData;
  methods?: StepperMethods<TData, T>;
  children?: ReactNode;
  initialStepName?: string;
  defaultOptions?: Partial<StepDescriptor<T>["options"]>;

  extraData?: any; // just like FlatList's extraData
  onSubmit?: (stepper: IStepper<TData, T>) => void;
  onExit?: (stepper: IStepper<TData, T>) => void;
} & Omit<TLayout, "steps" | "current" | "children">;

function Stepper<TData, T, TLayout extends StepperLayoutProps<T>>({
  initialData,
  initialStepName,
  layout: LayoutComponent,
  children,
  methods = BaseStepperMethods(),
  extraData,
  onSubmit,
  onExit,
  defaultOptions,
  ...layoutProps
}: StepperProps<TData, T, TLayout>) {
  const [state, setState] = useState<StepperState<TData, T>>();
  const steps = getStepsFromChildren<TData, T>(children);
  const stepsKey = steps.map((it) => it.key).join("|");

  const setCurrentOptions = useCallback(
    (options: PartialNull<T>, index = state?.index) => {
      if (!state || typeof index !== "number") return;
      setState({
        ...state,
        overrides: { ...state.overrides, [index]: options }
      });
    },
    [state]
  );

  let accumIndex = 0;
  const descriptors = steps.reduce(
    (accum, { name, options, index, component, hidden: hiddenProp }) => {
      const StepComp = component;
      const setOptions = (newOptions: PartialNull<T>) =>
        setCurrentOptions(newOptions, index);
      const accumOptions = {
        ...defaultOptions,
        ...options,
        ...state?.overrides?.[index]
      };

      const hidden = !!hiddenProp?.(state?.data ?? initialData);
      if (!hidden) accumIndex++;

      accum[name] = {
        options: accumOptions,
        index,
        normalizedIndex: accumIndex,
        key: name,
        hidden,
        render: () => (
          <StepComp
            stepper={stepper}
            options={accumOptions}
            setOptions={setOptions}
          />
        )
      };
      return accum;
    },
    {} as Record<string, StepDescriptor<T>>
  );

  // Refresh the state only when the steps change
  useEffect(() => {
    if (!state) {
      /// initial state
      setState(methods.getInitialState(steps, initialData, initialStepName));
    } else {
      /// update state from steps change
      setState(methods.updateSteps(steps, state));
    }
  }, [stepsKey, extraData]);

  const stepper = useMemo(() => {
    if (!state) return stepperStub as IStepper<TData, T>; // still being loaded

    const hasNextStep = state.index < state.steps.length - 1;
    const hasPreviousStep = state.index > 0;
    const withData = (data?: Partial<TData>) => ({
      ...state,
      data: { ...state.data, ...data }
    });

    const stepper: IStepper<TData, T> = {
      ...state,
      hasNextStep,
      hasPreviousStep,
      setOptions: setCurrentOptions,
      setData: (data) => setState(withData(data)),
      prevStep: (data) => setState(methods.prevStep(withData(data))),
      nextStep: (data) => setState(methods.nextStep(withData(data))),
      gotoStep: (step, data) => setState(methods.gotoStep(step, withData(data)))
    };

    return stepper;
  }, [state]);

  const visibleSteps = useMemo(
    () => state?.steps.filter((s) => !descriptors[s.name]?.hidden) ?? [],
    [state, descriptors]
  );

  const step = state?.steps[state.index];
  const current = step?.name ? descriptors?.[step.name] : null;

  useEffect(() => {
    if (!state) return;

    if (current?.hidden === true) {
      console.warn("Warning: Stepper got in a weird state.");
      return;
    }

    const curIndex = state?.index ?? 0;

    if (curIndex >= state.steps.length) onSubmit?.(stepper);
    else if (curIndex < 0) onExit?.(stepper);
  }, [state?.index]);

  /// waiting stepper to be ready
  if (!step || !current) return null;

  return (
    <Context.Provider value={stepper as IStepper<unknown, unknown>}>
      <LayoutComponent
        {...(layoutProps as unknown as TLayout)}
        current={current}
        steps={visibleSteps}
      >
        <Current.Provider value={current}>
          {current.hidden ? null : current.render()}
        </Current.Provider>
      </LayoutComponent>
    </Context.Provider>
  );
}

export type TypedStepper<
  TData,
  TLayout extends ComponentType<any>
> = ComponentProps<TLayout> extends StepperLayoutProps<infer T>
  ? {
      Stepper: ComponentType<StepperProps<TData, T, ComponentProps<TLayout>>>;
      Step: ComponentType<StepConfigProps<TData, T>>;
    }
  : never;

export function createStepper<TData, TLayout extends ComponentType<any>>() {
  return { Stepper, Step } as TypedStepper<TData, TLayout>;
}
