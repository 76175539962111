import React, {
  type ComponentProps,
  type ReactElement,
  type ReactNode
} from "react";
import { type IconSquare, Tag } from "../atoms";
import { Text } from "../quarks";
import type { Color } from "../style/theme/colors";
import ActionRow from "./ActionRow";
import Collapsible from "./Collapsible";

interface Props {
  variant?: "surface" | "outline";
  title: string | ReactElement<Text>;
  note?: string;
  icon: ComponentProps<typeof IconSquare>["icon"];
  iconColor: ComponentProps<typeof IconSquare>["color"];
  noteColor?: ComponentProps<typeof Text>["color"];
  children?: ReactNode;
  startCollapsed?: boolean;
  testID: string;
  tagLabel?: string;
  tagColor?: Color;
  leftSpacing?: "medium" | "small";
}

const CollapsibleRow = ({
  variant = "surface",
  icon,
  leftSpacing,
  iconColor = "primary",
  startCollapsed = false,
  title,
  note,
  children,
  noteColor,
  tagLabel,
  tagColor,
  testID
}: Props) => {
  return (
    <Collapsible
      noPad
      size="none"
      variant={variant}
      header={
        <ActionRow
          testID={`${testID}-row`}
          variant="flat"
          icon={icon}
          iconColor={iconColor}
          title={title}
          right={tagLabel ? <Tag label={tagLabel} color={tagColor} /> : null}
          leftSpacing={leftSpacing}
        >
          {!!note && (
            <Text variant="note" color={noteColor}>
              {note}
            </Text>
          )}
        </ActionRow>
      }
      startCollapsed={startCollapsed}
      testID={testID}
    >
      {children}
    </Collapsible>
  );
};

export default CollapsibleRow;
