import { DekigotoError } from "@gigsmart/dekigoto";
import arrayToSentence from "array-to-sentence";
import { createClient } from "graphql-ws";
import { noop } from "lodash";
import debounce from "lodash/debounce";
import remove from "lodash/remove";
import { fetchQuery as relayFetchQuery } from "react-relay";
import {
  type AuthMiddlewareOpts,
  type MiddlewareRaw,
  type MiddlewareSync,
  type PayloadData,
  RelayNetworkLayer,
  RelayNetworkLayerRequestBatch,
  RelayNetworkLayerRequest as RelayNetworkRequest,
  type RelayRequestAny,
  type RelayNetworkLayerResponse as RelayResponse,
  type Variables,
  authMiddleware,
  loggerMiddleware as defaultloggerMiddleware,
  perfMiddleware,
  retryMiddleware,
  urlMiddleware
} from "react-relay-network-modern/es";
import {
  type CacheConfig,
  Environment,
  type FetchQueryFetchPolicy,
  type GraphQLTaggedNode,
  type IEnvironment,
  Observable,
  type OperationType,
  RecordSource,
  Store,
  type SubscribeFunction
} from "relay-runtime";
import type { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes";
import JSWebSocket from "ws";
import type { LoaderFn } from "../loader";
import type { PayloadError } from "../payload-error";
import type { RelayMiddleware, RelayMiddlewareAny, Subscriber } from "../types";
import {
  type RelayContextOrchestratorOptions,
  type RelayOrchestratorProp,
  defaultOptions
} from "./context";
import { logger } from "./logger";
import batchMiddleware from "./middleware/batcher";
import errorCodesMiddleware from "./middleware/error-codes-middleware";
import { etagCacheMiddleware } from "./middleware/etag-cache-middleware";
import persistedQueryMiddleware from "./middleware/persisted-query";
import sessionPinningMiddleware from "./middleware/session-pinning-middleware";

export { RelayNetworkLayerRequest as RelayNetworkRequest } from "react-relay-network-modern/es";

const WebSocketImpl =
  typeof WebSocket === "undefined" ? JSWebSocket : WebSocket;

interface Operation {
  name: string;
  kind: string;
}
export class RelayRequestError extends DekigotoError {
  constructor(
    public readonly operation: Operation,
    public readonly variables: Variables,
    public readonly data?: PayloadData,
    public readonly payloadErrors?: readonly PayloadError[],
    public readonly cause?: Error
  ) {
    const errorSentence = payloadErrors
      ? arrayToSentence(payloadErrors.map(({ message }) => message))
      : "Unknown Error";
    super(cause ?? errorSentence);
    // biome-ignore lint/correctness/noConstructorReturn: <explanation>
    if (cause instanceof RelayRequestError) return cause;
    this.name = "RelayRequestError";
  }

  static nonCriticalCodes = new Set([
    "UNAUTHORIZED",
    "INVALID",
    "NOT_FOUND",
    "OTP_INVALID"
  ]);

  get criticalErrors() {
    const nonCriticalErrors = this.nonCriticalErrors;
    return this.payloadErrors?.filter(
      (payloadError) => !nonCriticalErrors?.includes(payloadError)
    );
  }

  get nonCriticalErrors() {
    return this.payloadErrors?.filter((payloadError) => {
      return (
        !payloadError.code ||
        !!payloadError.code.match(/^E\d+/) ||
        RelayRequestError.nonCriticalCodes.has(payloadError.code)
      );
    });
  }

  isCritical() {
    return !!this.cause || !!this.criticalErrors?.length;
  }
}

export type FetchQueryFn = <TOperation extends OperationType>(
  query: GraphQLTaggedNode,
  variables: TOperation["variables"]
) => Promise<TOperation["response"]>;

export interface RelayOrchestratorOptions
  extends Partial<RelayContextOrchestratorOptions> {
  readonly debug?: boolean;
  // Force refetch on queries on remount
  readonly alwaysFetch?: boolean;
  // The function called to transform payload errors
  readonly payloadErrorTransformer?: (error: PayloadError) => PayloadError;
  // The URL of the graphql endpoint we will be connecting to
  readonly graphqlUrl: string;
  // The URL of the websocket for subscriptions we will be connecting to
  readonly graphqlSocket: string;
  // On Service Unavailable
  onServiceUnavailable?: (reason: "gateway" | "network") => void;
  // The function called when setting a new token
  readonly setTokenFn?: (
    nextToken: string | null | undefined
  ) => void | Promise<void>;
  // The function called when getting the current token
  readonly getTokenFn?: () =>
    | (string | null | undefined)
    | Promise<string | null | undefined>;

  // The function called when needing to refresh an expired token
  readonly refreshTokenFn?: (
    req: RelayRequestAny,
    res: RelayResponse
  ) => (string | null | undefined) | Promise<string | null | undefined>;
  // The function called before the environment/token is reset
  readonly beforeReset?: () => void | Promise<void>;
  // The function called after the environment/token is reset
  readonly afterReset?: () => void | Promise<void>;
  // The function called before a request is made
  readonly EXPERIMENTAL_mergeAst?: boolean;
  readonly EXPERIMENTAL_sessionPinning?: boolean;
  readonly EXPERIMENTAL_etagCaching?: boolean;
  readonly onRequest?: (
    req: RelayRequestAny,
    relay: RelayOrchestratorProp
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  // The function called after a request is made
  readonly onResponse?: (
    res: RelayResponse | null | undefined,
    relay: RelayOrchestratorProp
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  // The function called when a request errors
  readonly onRequestError?: (arg0: Error) => void;
  // The function called when the subscribe function fails
  readonly onSubscriptionError?: (error: Error) => void;
  // The function called used to persist the active store
  readonly persistStore?: (arg0: RecordSource) => void;
  // The function called used to restore the store
  readonly restoreStore?: () => Promise<RecordMap | undefined>;
  // The function used to set additional headers on the request
  readonly fetchTimeout?: number;
  readonly requestHeaders?:
    | (() => Promise<Record<string, string>> | Record<string, string>)
    | Record<string, string>;
  // Middleware used to queue requests
  readonly queueMiddleware?: RelayMiddlewareAny | null | undefined;
  // A function used to build middlewares
  readonly buildMiddlewares?: (
    middlewares: Array<RelayMiddlewareAny | null>
  ) => Array<RelayMiddlewareAny | null>;
  // Middleware used to log requests
  readonly loggerMiddleware?: RelayMiddlewareAny;
  // The function called when the socket fails to connect
  readonly onSocketError?: (arg0: Error) => void;
  readonly onSocketChange?: (arg0: "connected" | "disconnected") => void;
  // Error codes to report
  readonly reportErrorCodes?: string[] | "*";
  // Error code Report function
  readonly errorCodeReporter?: (
    error: PayloadError,
    request: RelayRequestAny
  ) => void;

  // Enable Persisted Queries,
  readonly enablePersistedQueries?: boolean;

  // Query Cache Expiration Time
  readonly queryCacheExpirationTime?: number;

  // Set a retry delay
  readonly retryDelay?: number;

  // Enable subcriptions
  readonly enableSubscriptions?: boolean;

  // Decorate queries with this defined hook
  readonly useDecorateQuery?: (
    name: string,
    retry: () => Promise<void> | void
  ) => void;

  // UNSAFE injected relay environment
  readonly __UNSAFE_environment?: IEnvironment;
}

export async function createRelayOrchestrator(
  options: RelayOrchestratorOptions
): Promise<RelayOrchestratorProp> {
  const loaders: LoaderFn[] = [];
  const subscribers: Subscriber[] = [];
  const requestIds: symbol[] = [];
  let recordSource: RecordSource = new RecordSource({});
  let defaultToken: string | null | undefined = "";

  const {
    graphqlUrl,
    graphqlSocket,
    setTokenFn: initialSetTokenFn,
    getTokenFn: initialGetTokenFn,
    refreshTokenFn,
    beforeReset,
    afterReset,
    onRequest,
    onResponse,
    onSubscriptionError,
    onRequestError,
    onQueryCompleted = defaultOptions.onQueryCompleted,
    onQueryError = defaultOptions.onQueryError,
    onMutationCompleted = defaultOptions.onMutationCompleted,
    onMutationError = defaultOptions.onQueryError,
    onSubscriptionNext = defaultOptions.onSubscriptionNext,
    persistStore,
    EXPERIMENTAL_sessionPinning: sessionPinning,
    EXPERIMENTAL_etagCaching: etagCaching,
    restoreStore,
    queueMiddleware,
    loggerMiddleware,
    onSocketError,
    enableSubscriptions = true,
    fetchTimeout = 60_000,
    retryDelay = 500,
    onServiceUnavailable,
    requestHeaders = {},
    buildMiddlewares = (middlewares) => middlewares,
    reportErrorCodes,
    alwaysFetch,
    queryCacheExpirationTime,
    onSocketChange,
    debug = false,
    useDecorateQuery = noop,
    errorCodeReporter = (error: PayloadError, request: RelayRequestAny) => {
      if (request instanceof RelayNetworkRequest) {
        logger.warn(
          `${error.code}: ${error.message} in ${
            request.operation.name
          } at \`${error.path?.join(".")}\``
        );
      }
    },
    enablePersistedQueries,
    payloadErrorTransformer = (error) => {
      const { field, message } = error;
      if (!field || !message) return error;
      error.message = `${field}: ${message}`;
      return error;
    },
    __UNSAFE_environment
  } = options;

  const setTokenFn = async (nextToken: string | null | undefined) => {
    initialSetTokenFn
      ? await initialSetTokenFn(nextToken)
      : (defaultToken = nextToken);
    return await _initEnvironment(true);
  };

  const getTokenFn = async () =>
    (initialGetTokenFn ? await initialGetTokenFn() : defaultToken) ?? "";

  // Register a loader
  const __UNSAFE_registerLoader = (loader: LoaderFn) => {
    loaders.push(loader);
    _updateLoader(loader);
  };

  // Deregister a loader
  const __UNSAFE_deregisterLoader = (loader: LoaderFn) => {
    remove(loaders, (registered) => registered === loader);
  };

  // Register a subscriber
  let activeConnection = false;
  const __UNSAFE_registerSubscriber = (subscriber: Subscriber) => {
    subscribers.push(subscriber);
    if (activeConnection) subscriber(true);
  };

  // Deregister a subscriber
  const __UNSAFE_deregisterSubscriber = (subscriber: Subscriber) => {
    remove(subscribers, (registered) => registered === subscriber);
  };

  const _handleSocketOpen = () => {
    activeConnection = true;
    logger.info("socket opened");
    subscribers.forEach((subscriber) => subscriber(true));
  };

  const _handleSocketClosed = () => {
    activeConnection = false;
    logger.info("socket closed");
    subscribers.forEach((subscriber) => subscriber(false));
    onSocketChange?.("disconnected");
  };

  // Handle Loading
  const _handleLoading = (id: symbol) => {
    requestIds.push(id);
    _updateLoaders();
  };

  const _handleLoaded = (id: symbol) => {
    remove(requestIds, (tracked) => id === tracked);
    _updateLoaders();
  };

  const _updateLoaders = () => {
    loaders.forEach(_updateLoader);
  };

  const _updateLoader = (loader: LoaderFn) => {
    const isLoading = requestIds.length > 0;
    const progress = 1 / (requestIds.length + 1);
    loader({ isLoading, progress, queriesPending: requestIds.length });
  };

  // Fetch a query using the current environment
  const fetchQuery = async <TOperation extends OperationType>(
    query: GraphQLTaggedNode,
    variables: TOperation["variables"],
    {
      fetchPolicy,
      networkCacheConfig
    }: {
      fetchPolicy?: FetchQueryFetchPolicy;
      networkCacheConfig?: CacheConfig;
    } = {}
  ): Promise<TOperation["response"] | undefined> =>
    // eslint-disable-next-line @typescript-eslint/return-await
    await relayFetchQuery(environment, query, variables, {
      fetchPolicy,
      networkCacheConfig
    }).toPromise();

  // Create a subscriber connection over the phoenix socket
  let wsClient: ReturnType<typeof createClient> | undefined;
  const _generateSubscriber = async (): Promise<SubscribeFunction> => {
    await disconnectSocket();

    logger.info("graphqlSocket", graphqlSocket);
    let activeSocket: WebSocket;
    const nextClient = createClient({
      url: graphqlSocket,
      webSocketImpl: WebSocketImpl,
      connectionParams: async () => ({
        ...(typeof requestHeaders === "function"
          ? await requestHeaders()
          : requestHeaders),
        Authorization: `Bearer ${await getTokenFn()}`
      }),
      keepAlive: 10_000,
      on: {
        closed: _handleSocketClosed,
        opened: _handleSocketOpen,
        connected: (socket, params) => {
          logger.info("socket connected", params);
          const nextValidToken = params?.["next-valid-token"];
          if (nextValidToken) setTokenFn(String(nextValidToken));
          activeSocket = socket as WebSocket;
          onSocketChange?.("connected");
        },
        error: (error) => {
          let e: Error;

          switch (typeof error) {
            case "string":
              e = new Error(
                `Socket (${graphqlSocket}) errored with message ${error}`
              );
              break;
            case "number":
              e = new Error(
                `Socket (${graphqlSocket}) errored with code ${error}`
              );
              break;
            default:
              if (!(error instanceof Error)) return;
              e = error;
          }

          onSocketError?.(e);
        }
      }
    });
    wsClient = nextClient;
    const subscribe: SubscribeFunction = (operation, variables) => {
      return Observable.create<any>((sink) => {
        const operationName = operation.name;
        const query = operation.text ?? operation.id;
        const customSink: typeof sink = {
          next: (data) => {
            logger.info(operationName, "sink next", data);
            sink.next(data);
          },
          error: (error) => {
            logger.error(operationName, "sink error", error);
            if (onSubscriptionError) onSubscriptionError(error as Error);
            sink.error(error as Error);
          },
          complete: () => {
            logger.info(operationName, "sink complete");
            sink.complete();
          },
          get closed() {
            return sink.closed;
          }
        };
        if (!query) {
          return sink.error(new Error("Operation text cannot be empty"));
        }
        return nextClient.subscribe(
          {
            operationName,
            query,
            variables
            // ...(operation.id
            //   ? { extensions: { persistedQuery: operation.id } }
            //   : {})
          },
          customSink
        );
      });
    };

    return subscribe;
  };

  // Generate Callbacks
  const _generateCallbacksMiddleware: RelayMiddleware =
    (next) => async (req) => {
      if (onRequest) onRequest(req, orchestrator);
      if (req instanceof RelayNetworkLayerRequestBatch) return await next(req);
      let response: RelayResponse;
      try {
        response = !req.operation.name.includes("Silent")
          ? await invokeLoad(next(req))
          : await next(req);

        if (response.errors?.length) {
          const error = new RelayRequestError(
            req.operation,
            req.variables,
            response.data,
            response.errors
          );
          if (error.isCritical()) throw error;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const shouldIgnore =
          err.message === "Failed to fetch" || err.name === "AbortError";
        const requestError = new RelayRequestError(
          req.operation,
          req.variables,
          undefined,
          undefined,
          err
        );

        if (!shouldIgnore && onRequestError) onRequestError(requestError);
        if ((err.res as RelayResponse) && onResponse) {
          onResponse(err.res, orchestrator);
        }

        throw requestError;
      }

      if (onResponse) onResponse(response, orchestrator);
      return response;
    };

  async function invokeLoad<T>(fn: Promise<T>): Promise<T> {
    const id = Symbol("request");
    _handleLoading(id);
    return await fn
      .catch((err) => {
        _handleLoaded(id);
        throw err;
      })
      .then((result) => {
        _handleLoaded(id);
        return result;
      });
  }

  const authOpts: AuthMiddlewareOpts = {
    allowEmptyToken: true,
    token: getTokenFn,
    tokenRefreshPromise:
      refreshTokenFn &&
      (async (req, res) => {
        const nextToken: string = (await refreshTokenFn(req, res)) ?? "";
        await setTokenFn(nextToken);
        return nextToken;
      })
  };

  // Create a subscriber connection over the phoenix socket
  const _generateMiddlewares = (): Array<
    RelayMiddleware | MiddlewareSync | MiddlewareRaw | null
  > => {
    logger.info("graphqlUrl", graphqlUrl);
    const retryDelays = (attempt: number) => {
      const delay = attempt < 3 && 2 ** (attempt + 2) * retryDelay;
      return delay;
    };
    return buildMiddlewares([
      reportErrorCodes
        ? errorCodesMiddleware(reportErrorCodes, errorCodeReporter)
        : null,
      loggerMiddleware ?? null,
      urlMiddleware({
        url: async (req) =>
          `${await Promise.resolve(graphqlUrl ?? "")}/${req.operation.name}`
      }),
      logger.isEnabled() ? perfMiddleware({ logger: logger.info }) : null,
      logger.isEnabled()
        ? defaultloggerMiddleware({ logger: logger.info })
        : null,
      persistStore
        ? generatePersistenceMiddleware(persistStore, recordSource)
        : null,
      _generateCallbacksMiddleware,
      retryMiddleware({
        fetchTimeout,
        retryDelays,
        statusCodes: [500, 502, 504],
        logger: logger.info
      }),
      retryMiddleware({
        fetchTimeout,
        retryDelays,
        beforeRetry: ({ lastError, attempt }) => {
          if (attempt <= 3) return;
          const isConnectIssue = lastError?.name === "TypeError";
          const is503 =
            lastError?.name === "RRNLRetryMiddlewareError" &&
            lastError.message.includes("503");
          if (is503 || isConnectIssue) {
            onServiceUnavailable?.(is503 ? "gateway" : "network");
          }
        },
        allowMutations: true,
        statusCodes: [503],
        logger: logger.info
      }),
      queueMiddleware ?? null,
      batchMiddleware({
        batchUrl: async (batchKey) =>
          `${await Promise.resolve(graphqlUrl ?? "")}/${batchKey}Batch`,
        batchTimeout: 60,
        headers: requestHeaders,
        auth: authOpts
      }),
      generateHeadersMiddleware(requestHeaders),
      authMiddleware(authOpts),
      enablePersistedQueries ? persistedQueryMiddleware({ debug }) : null,
      (next) => async (req) => {
        req.fetchOpts.credentials = "omit";
        return await next(req);
      },
      etagCaching ? etagCacheMiddleware() : null,
      sessionPinning ? sessionPinningMiddleware() : null
    ]);
  };

  // Initialize the environment
  const _initEnvironment = async (blank?: boolean): Promise<IEnvironment> => {
    if (__UNSAFE_environment) return __UNSAFE_environment;
    logger.info("Initializing Relay Environment", {
      graphqlUrl,
      graphqlSocket
    });
    const data = !blank && restoreStore ? await restoreStore() : {};
    recordSource = new RecordSource(data);
    const store = new Store(recordSource, { queryCacheExpirationTime });
    const network = new RelayNetworkLayer(_generateMiddlewares(), {
      noThrow: true,
      // @ts-expect-error
      subscribeFn: enableSubscriptions ? await _generateSubscriber() : undefined
    });
    return new Environment({ network, store });
  };

  const disconnectSocket = async () => {
    if (!wsClient) return;
    await wsClient.dispose();
    wsClient = undefined;
  };

  // Handle a reset of the relay environment
  const reset = async (newToken: string | null | undefined = null) => {
    // TODO: notify/abort pending operations?
    await disconnectSocket();
    if (beforeReset) await beforeReset();
    recordSource.clear();
    persistStore?.(recordSource);
    environment = await setTokenFn(newToken);
    if (afterReset) await afterReset();
    return environment;
  };

  let environment = await _initEnvironment();

  const orchestrator: RelayOrchestratorProp = {
    __UNSAFE_registerLoader,
    __UNSAFE_deregisterLoader,
    __UNSAFE_registerSubscriber,
    __UNSAFE_deregisterSubscriber,
    get environment(): IEnvironment {
      return environment;
    },
    alwaysFetch,
    payloadErrorTransformer,
    reset,
    onQueryCompleted,
    onQueryError,
    onMutationCompleted,
    onMutationError,
    onSubscriptionNext,
    fetchQuery,
    invokeLoad,
    useDecorateQuery,
    enableSubscriptions,
    clone: async () => await createRelayOrchestrator(options)
  };

  return orchestrator;
}

// Generate the persistence middleware
function generatePersistenceMiddleware(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  persistStoreFn: (arg0: RecordSource) => any,
  recordSource: RecordSource
): RelayMiddleware {
  const persistStore = debounce(persistStoreFn, 1000);
  return (next) => async (req) => {
    const res = await next(req);
    if (persistStore) persistStore(recordSource);
    return res;
  };
}

const generateHeadersMiddleware =
  (
    requestHeaders: RelayOrchestratorOptions["requestHeaders"]
  ): RelayMiddleware =>
  (next) =>
  async (req) => {
    Object.assign(
      req.fetchOpts.headers,
      typeof requestHeaders === "function"
        ? await requestHeaders()
        : requestHeaders
    );
    // Always assume a json response
    req.fetchOpts.headers.Accept = "application/json";
    return await next(req);
  };
