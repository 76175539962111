import { DeveloperDebug } from "@gigsmart/feature-flags";
import { type FlagSpec, FlagType } from "@gigsmart/feature-flags/registry";
import { registerFlag } from "./flagDomain";

let isDev = false;

if (typeof __DEV__ !== "undefined") {
  isDev = __DEV__;
}

const LogLevelAll = registerFlag("log-level-all", {
  type: isDev ? FlagType.PINNED : FlagType.REMOTE,
  inherits: DeveloperDebug,
  override: () =>
    typeof process.env.LOG_LEVEL !== "undefined"
      ? process.env.LOG_LEVEL === "*" || false
      : undefined
});

const LogTimestamps = registerFlag("log-timestamps");

export type LogFn = (message?: any, ...optionalParams: any[]) => void;

export interface Backend {
  log: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
  debug: LogFn;
  trace: LogFn;
}

const backends: Backend[] = [];

const createMethodsObject = () => {
  const [_, methods] = [
    "trace",
    "debug",
    "error",
    "warn",
    "info",
    "log"
  ].reduce<[FlagSpec, Record<keyof Backend, LogFn>]>(
    ([prev, map], level) => {
      const flag = registerFlag(`log-level-${level}`, {
        type: FlagType.REMOTE,
        inherits: prev,
        override: () => (process.env.LOG_LEVEL === level ? true : undefined)
      });
      return [
        flag,
        {
          ...map,
          [level as keyof Backend]: (
            message?: any,
            ...optionalParams: any[]
          ) => {
            if (flag.isDisabled()) return;
            backends.forEach((backend) => {
              try {
                const timestamp = new Date().toISOString();
                backend[level as keyof Backend](
                  ...(LogTimestamps.isEnabled() ? [timestamp] : []),
                  message,
                  ...optionalParams
                );
              } catch (e) {
                // do nothing
              }
            });
          }
        }
      ];
    },
    [LogLevelAll, {} as Record<string, LogFn>]
  );
  return methods;
};

export const methods = createMethodsObject();

export function registerBackend(
  name: string,
  backend: Backend,
  flagType = FlagType.REMOTE
) {
  const { isEnabled, isDisabled } = registerFlag(`log-backend-${name}`, {
    type: flagType
  });
  if (isEnabled()) backends.push(backend);
  return { isEnabled, isDisabled, ...backend };
}

export function clearBackends() {
  backends.length = 0;
}
