import md5 from "md5";
import {
  type ConcreteBatch,
  type RelayNetworkLayerRequest,
  RelayNetworkLayerRequestBatch
} from "react-relay-network-modern/es";
import { createRawMiddleware } from "../middleware";

const queryCache: Record<string, Promise<string>> = {};

async function getRemoteQuery(operationId: string | null) {
  if (!operationId) return null;
  return await (queryCache[operationId] ??= fetch(
    `https://qps.gigsmart.com/${operationId}`
  ).then((r) => r.text()));
}

async function rebuildRequest<
  R extends RelayNetworkLayerRequest | RelayNetworkLayerRequestBatch
>(req: R, includeQuery = false): Promise<R> {
  const nextReq: R = req.clone() as R;

  if (nextReq instanceof RelayNetworkLayerRequestBatch) {
    nextReq.requests = await Promise.all(
      nextReq.requests.map((r) => {
        return rebuildRequest(r, includeQuery);
      })
    );
    nextReq.fetchOpts.body = nextReq.prepareBody();
    return nextReq;
  }

  const operation: ConcreteBatch = { ...nextReq.operation };

  // Sett the operation id
  operation.id ??= operation.cacheID ?? (operation.text && md5(operation.text));

  operation.text = includeQuery
    ? operation.text || (await getRemoteQuery(operation.id))
    : null;

  nextReq.operation = operation;
  if (operation.id) nextReq.id = operation.id;
  nextReq.fetchOpts.body = nextReq.prepareBody();
  return nextReq;
}

interface Options {
  debug?: boolean;
}

export default ({ debug = false }: Options) =>
  createRawMiddleware((next) => async (req) => {
    const response = await next(await rebuildRequest(req, debug));
    switch (response.status) {
      case 428:
        return await next(await rebuildRequest(req, true));
      default:
        return response;
    }
  });
