import InstrumentScroller, {
  type OnScrollParams
} from "@gigsmart/pickle/support/utils/instrument-scroller";
import { remove } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type DependencyList,
  type MutableRefObject
} from "react";
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
  type ScrollView as RNScrollView,
  type TextInput,
  findNodeHandle
} from "react-native";
import {
  KeyboardAwareScrollView,
  type KeyboardAwareScrollViewProps
} from "react-native-keyboard-aware-scroll-view";
import { type UseTransformFlexProps, useTransformFlexProps } from "../style";

type ScrollToFn = (arg0: OnScrollParams) => void;

export interface ScrollToProps {
  scrollable: boolean;
  scrollTo: ScrollToFn;
  addEndListener: (cb: (distanceToEnd: number) => void) => () => void;
}

const Context = createContext<ScrollToProps>({
  scrollable: true,
  scrollTo: () => {},
  addEndListener: () => () => {}
});

export const useScrollRef = () => useRef<RNScrollView>(null);
export const useScrollContext = () => useContext(Context);
export const useOnEndReached = (
  fn?: (distanceToEnd: number) => void,
  deps: DependencyList = []
) => {
  const { addEndListener } = useScrollContext();
  useEffect(() => {
    if (!fn) return;
    return addEndListener(fn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEndListener, ...deps]);
};

export interface ScrollViewProps
  extends Omit<
    UseTransformFlexProps & KeyboardAwareScrollViewProps,
    "direction" | "testID" | "innerRef" | "grow"
  > {
  testID: string;
  reverse?: boolean;
  innerRef?: MutableRefObject<RNScrollView | null>;
  grow?: boolean;
  onScrollToEnd?: (distanceToEnd: number) => void;
}

export function ScrollView({
  testID,
  horizontal,
  reverse,
  children,
  grow = false,
  onScroll,
  innerRef,
  scrollEnabled = true,
  onContentSizeChange,
  onScrollToEnd = () => {},
  onLayout,
  ...props
}: ScrollViewProps) {
  const scrollRef = useRef<KeyboardAwareScrollView>(null);
  const dir = horizontal ? "row" : "column";

  const [containerSize, setContainerSize] = React.useState<number>(-1);
  const [contentSize, setContentSize] = React.useState<number>(0);

  const transformedProps = useTransformFlexProps(
    {
      direction: reverse ? `${dir}-reverse` : `${dir}`,
      grow: grow ? 1 : undefined,
      ...props
    },
    "contentContainerStyle"
  );

  const handleScrollTo = useCallback((params: OnScrollParams) => {
    const scroller = scrollRef.current;
    if (!scroller) return;

    if (params === "start") {
      scroller.scrollToPosition(0, 0, true);
    } else if (params === "end") {
      scroller.scrollToEnd();
    } else if ("y" in params || "x" in params) {
      scroller.scrollToPosition(0, 0, true);
    } else if (
      "scrollIntoView" in scroller &&
      typeof scroller.scrollIntoView === "function"
    ) {
      const node = findNodeHandle(params as typeof TextInput);
      if (node) scroller.scrollIntoView(node);
    }
  }, []);
  const [scrollable, setScrollable] = useState(false);

  const listeners = useRef<Array<(distanceToEnd: number) => void>>([]);

  const addEndListener = useCallback(
    (cb: (distanceToEnd: number) => void) => {
      if (!scrollable) cb(0);
      listeners.current.push(cb);
      return () => {
        remove(listeners.current, (listener) => listener !== cb);
      };
    },
    [scrollable]
  );

  const handleScrollToEnd = useCallback(
    (distanceToEnd: number) =>
      [onScrollToEnd, ...listeners.current].forEach((cb) => cb(distanceToEnd)),
    []
  );

  const context = useMemo(
    () => ({
      scrollable: scrollEnabled && scrollable,
      scrollTo: handleScrollTo,
      handleScrollToEnd,
      addEndListener
    }),
    [
      addEndListener,
      handleScrollTo,
      handleScrollToEnd,
      scrollEnabled,
      scrollable
    ]
  );

  useEffect(() => {
    setScrollable(contentSize > containerSize);
  }, [containerSize, contentSize]);

  useEffect(() => {
    if (!scrollable) handleScrollToEnd(0);
  }, [scrollable]);

  const handleContentSizeChange = useCallback(
    (contentWidth: number, contentHeight: number) => {
      setContentSize(horizontal ? contentWidth : contentHeight);
      onContentSizeChange?.(contentWidth, contentHeight);
    },
    [horizontal, handleScrollToEnd]
  );

  const handleOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setContainerSize(
        horizontal
          ? event.nativeEvent.layout.width
          : event.nativeEvent.layout.height
      );
      onLayout?.(event);
    },
    [onLayout, horizontal]
  );

  const handleOnScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      onScroll?.(e);

      if (!e.nativeEvent) return;

      const contentSize = horizontal
        ? e.nativeEvent.contentSize.width
        : e.nativeEvent.contentSize.height;

      const containerSize = horizontal
        ? e.nativeEvent.layoutMeasurement.width
        : e.nativeEvent.layoutMeasurement.height;

      const contentOffset = horizontal
        ? e.nativeEvent.contentOffset.x
        : e.nativeEvent.contentOffset.y;

      const distanceToEnd = Math.max(
        contentSize - containerSize - contentOffset,
        0
      );

      if (distanceToEnd < 40) handleScrollToEnd(distanceToEnd);
    },
    [onScroll, context, horizontal]
  );

  return (
    <KeyboardAwareScrollView
      ref={scrollRef}
      innerRef={(ref) => {
        if (innerRef) innerRef.current = ref as any;
      }}
      onLayout={handleOnLayout}
      onContentSizeChange={handleContentSizeChange}
      scrollEnabled={scrollEnabled}
      extraScrollHeight={30}
      viewIsInsideTabBar
      enableOnAndroid={false}
      automaticallyAdjustContentInsets
      contentInsetAdjustmentBehavior="automatic"
      showsVerticalScrollIndicator={Platform.OS === "web"}
      keyboardShouldPersistTaps="handled"
      testID={testID}
      enableResetScrollToCoords={false}
      onScroll={handleOnScroll}
      scrollEventThrottle={32}
      {...transformedProps}
    >
      <Context.Provider value={context}>
        {children}
        <InstrumentScroller targetId={testID} onScroll={context.scrollTo} />
      </Context.Provider>
    </KeyboardAwareScrollView>
  );
}
