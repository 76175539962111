import React from "react";
import Button, { type Props as ButtonProps } from "../atoms/Button";
import showFilePicker from "./FilePicker";
import type { MediaPickerFile } from "./MediaPicker";

interface Props extends Omit<ButtonProps, "onPress" | "testID"> {
  onSelect: (item: MediaPickerFile[]) => void;
  acceptType?: "CSV" | "PDF" | "IMAGE";
  maxSelection?: number;
  testID?: string;
}

export default function FilePickerButton({
  onSelect,
  acceptType,
  maxSelection,
  icon = "upload",
  testID = "docs-uploader",
  label = "Upload Photos & Documents",
  variant = "clear",
  size = "small",
  outline = true,
  ...buttonProps
}: Props) {
  return (
    <Button
      {...buttonProps}
      label={label}
      variant={variant}
      outline={outline}
      size={size}
      icon={icon}
      testID={testID}
      onPress={async () =>
        await showFilePicker(onSelect, acceptType, maxSelection)
      }
    />
  );
}
