import { Platform } from "react-native";
import { type MediaPickerFile, showMediaPicker } from "../MediaPicker";
import { showDocumentPicker } from "./DocumentPicker";
import { showDocumentTypeSelectionModal } from "./DocumentTypeSelectionModal";
export { showDocumentPicker } from "./DocumentPicker";
export { DocumentTypes } from "./DocumentTypes";
export { showDocumentTypeSelectionModal } from "./DocumentTypeSelectionModal";

export default async function showFilePicker(
  onSelect: (value: MediaPickerFile[]) => void,
  acceptType?: "CSV" | "PDF" | "IMAGE",
  maxSelection?: number
) {
  if (Platform.OS === "web") {
    showDocumentPicker({ onSelect, acceptType, max: maxSelection });
  } else {
    showDocumentTypeSelectionModal({
      onDocumentPress: () => showDocumentPicker({ onSelect, acceptType }),
      onPhotoPress: () => showMediaPicker({ onSelect })
    });
  }
}
