import { memoize, pick } from "lodash";
import React, { type ChangeEvent, type ComponentProps } from "react";
import { StyleSheet, type TextInputProps } from "react-native";
import { unstable_createElement } from "react-native-web";
import TextMask, { type maskArray } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import colors from "../style/theme/colors";
import TextInput from "./TextInput";

export type ValidMask =
  | "time"
  | "date"
  | "phoneNumber"
  | "creditCard"
  | "money"
  | "money:3"
  | "money:4"
  | "oneDecimal"
  | "integer"
  | "integer:2";

interface Props extends ComponentProps<typeof TextInput> {
  mask: ValidMask;
}

export default function MaskedTextInput({ mask: maskName, ...props }: Props) {
  return (
    <TextInput
      {...props}
      renderInputComponent={(inputProps) => {
        const { format, ...maskProps } = computeMaskProps(maskName, inputProps);

        return unstable_createElement<any>(TextMask, {
          // ref,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            if (format && e.target?.value)
              e.target.value = format(e.target.value);

            inputProps.onChange?.(e as any);
            inputProps.onChangeText?.(e.target.value);
          },
          onKeyDown: (e: KeyboardEvent) => {
            if (e.key === "Enter" && inputProps.onSubmitEditing)
              inputProps.onSubmitEditing(e as any);
          },
          guide: true,
          ...mapInputProps(inputProps),
          ...maskProps
        });
      }}
    />
  );
}

const styles = StyleSheet.create({
  textinput: {
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    backgroundColor: colors.clear.fill,
    border: "0 solid black",
    borderRadius: 0,
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
    resize: "none"
  }
});

const forwardPropsList = [
  "accessibilityLabel",
  "accessibilityLiveRegion",
  "accessibilityRole",
  "accessibilityState",
  "accessibilityValue",
  "accessible",
  "autoFocus",
  "defaultValue",
  "maxLength",
  "placeholder",
  "pointerEvents",
  "testID",
  "value",
  // events
  "onBlur"
];

const mapInputProps = ({
  placeholderTextColor,
  style,
  keyboardType,
  editable = true,
  ...props
}: TextInputProps) => {
  let type;
  switch (keyboardType) {
    case "email-address":
      type = "email";
      break;
    case "number-pad":
    case "numeric":
      type = "number";
      break;
    case "phone-pad":
      type = "tel";
      break;
    // case "search":
    case "web-search":
      type = "search";
      break;
    case "url":
      type = "url";
      break;
    default:
      type = "text";
  }

  return {
    ...pick(props, forwardPropsList),
    style: [styles.textinput, style, { placeholderTextColor }],
    type,
    readOnly: !editable
  };
};

const readMask = (str: string) => {
  const dict: Record<string, string | RegExp> = {
    "#": /\d/
  };
  return str.split("").map((d) => dict[d] ?? d);
};

const getMoneyMask = memoize((integerLimit?: number) =>
  createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    includeThousandsSeparator: false,
    decimalLimit: 2,
    integerLimit
  })
);

const getDecimalMask = memoize((integerLimit?: number, decimalLimit?: number) =>
  createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    includeThousandsSeparator: false,
    decimalLimit,
    integerLimit
  })
);

const masks = {
  timeMask2: readMask("##"),
  timeMask3: readMask("#:##"),
  timeMask4: readMask("##:##"),
  dateMask: readMask("##/##/####"),
  phoneNumberMask: readMask("(###) ###-####"),
  ccAmex: readMask("#### ###### #####"),
  cc: readMask("#### #### #### ####"),
  oneDecimal: readMask("##.#"),
  integer: readMask("############"),
  integer2: readMask("##")
};

export type ComputeMaskFn<P, T = TextInputProps> = (
  mask: ValidMask | undefined,
  props: T
) => P | undefined;

const isAmex = (cc?: string) => !!cc && /^(34|37)/.test(cc);

const toDigits = (text: string) => text.replace(/[^0-9]/g, "");

type MaskProps = {
  mask: undefined | maskArray | ((value?: string | null) => maskArray);
  maxLength?: number;
  guide?: boolean;
  format?: (text: string) => string;
  onBlur?: TextInputProps["onBlur"];
};

const computeMaskProps = (
  mask: ValidMask,
  {
    value,
    onBlur,
    onChangeText
  }: Pick<TextInputProps, "value" | "onBlur" | "onChangeText">
): MaskProps => {
  let integerLimit: string;

  switch (mask) {
    case "time":
      return {
        maxLength: 5,
        guide: false,
        mask: (val) => {
          const length = toDigits(val || "").length;
          return length < 3
            ? masks.timeMask2
            : length < 4
              ? masks.timeMask3
              : masks.timeMask4;
        },
        format: (val) => {
          const digits = toDigits(val || "").slice(0, 4);
          const n = digits.length - 2;
          return digits.length < 3
            ? digits
            : `${digits.slice(0, n)}:${digits.slice(n)}`;
        },
        onBlur: (e) => {
          if (value && onChangeText) {
            const [h, m] = value.split(":");
            let fmtValue = value;
            if (h?.length === 1) fmtValue = `0${fmtValue}`;
            if (!m) fmtValue = `${fmtValue}:00`;
            if (fmtValue !== value) onChangeText(fmtValue);
          }
          onBlur?.(e);
        }
      };
    case "integer":
      return { mask: masks.integer, guide: false };
    case "integer:2":
      return { mask: masks.integer2, guide: false };
    case "date":
      return { mask: masks.dateMask };
    case "phoneNumber":
      return { mask: masks.phoneNumberMask };
    case "creditCard":
      return {
        mask: isAmex(value) ? masks.ccAmex : masks.cc
      };
    case "oneDecimal":
      return {
        mask: getDecimalMask(3, 1),
        onBlur: (e: any) => {
          if (value && !Number.isNaN(Number(value))) {
            const fmtValue = Number(value).toFixed(1);
            if (fmtValue !== value) onChangeText?.(fmtValue);
          }
          onBlur?.(e);
        }
      };
    case "money":
    case "money:3":
    case "money:4":
      [, integerLimit = ""] = mask.split(":");
      return {
        mask: getMoneyMask(
          integerLimit ? Number.parseInt(integerLimit, 10) : undefined
        ),
        onBlur: (e: any) => {
          if (value && !Number.isNaN(Number(value))) {
            const fmtValue = Number(value).toFixed(2);
            if (fmtValue !== value) onChangeText?.(fmtValue);
          }
          onBlur?.(e);
        }
      };
  }
};
