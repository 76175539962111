import React, { useCallback, useRef, useState } from "react";
import { Animated, View, useWindowDimensions } from "react-native";
import { Button, ContentArea, IconCircle } from "../../atoms";
import {
  Column,
  Icon,
  type IconName,
  Pressable,
  Row,
  ScrollView,
  Text
} from "../../quarks";
import { useStyles } from "../../style";

interface FilterProps {
  children: React.ReactNode;
  filterCount: number;
  onResetFilters: () => void;
}

export default function DesktopFilter({
  children,
  filterCount = 0,
  onResetFilters
}: FilterProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const slideAnim = useRef(new Animated.Value(0)).current;
  const { height: windowHeight } = useWindowDimensions();

  const styles = useStyles(({ getColor, getUnits }) => {
    return {
      container: {
        position: "absolute",
        right: 0,
        top: 0,
        width: getUnits(33)
      },
      drawer: {
        position: "absolute",
        right: 0,
        top: 0,
        width: getUnits(85),
        backgroundColor: getColor("surface", "fill"),
        transform: [{ translateX: getUnits(85) - getUnits(33) }]
      },
      drawerShadow: {
        shadowColor: getColor("black", "fill"),
        shadowOffset: {
          width: -2,
          height: 2
        },
        shadowOpacity: 0.15,
        shadowRadius: 3,
        elevation: 5
      },
      headerButton: {
        width: getUnits(33)
      },
      headerRow: {
        backgroundColor: getColor("highlight", "fill", { opacity: 0.08 }),
        width: "100%",
        paddingRight: 16
      },
      headerRowRounded: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        overflow: "hidden"
      },
      iconContainer: {
        minHeight: getUnits(5),
        justifyContent: "center"
      },
      counter: {
        width: getUnits(5),
        height: getUnits(5),
        borderRadius: getUnits(3),
        backgroundColor: getColor("primary", "fill"),
        justifyContent: "center",
        alignItems: "center"
      },
      contentContainer: {
        height: windowHeight
      }
    };
  }, []);

  const toggleDrawer = useCallback(() => {
    const toValue = isDrawerOpen ? 0 : 1;

    Animated.spring(slideAnim, {
      toValue,
      useNativeDriver: true,
      tension: 52,
      friction: 11
    }).start();

    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, slideAnim]);

  const translateX = slideAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [85 * 4 - 33 * 4, 0]
  });

  const ButtonLayout = ({ icon }: { icon: IconName }) => {
    return (
      <Row gap="medium">
        <Column justifyContent="center">
          <IconCircle
            icon={icon}
            size="tiny"
            color="primary"
            variant="well"
            opacity={0.16}
          />
        </Column>
        <ContentArea size="slim" variant="none">
          <Row gap="compact" alignItems="center">
            {filterCount > 0 ? (
              <View style={styles.counter}>
                <Text color="white" variant="subheader">
                  {filterCount}
                </Text>
              </View>
            ) : (
              <View style={styles.iconContainer}>
                <Icon
                  name="filter"
                  variant="solid"
                  size="small"
                  color="primary"
                />
              </View>
            )}
            <Text variant="subheader" color="primary">
              Filters
            </Text>
          </Row>
        </ContentArea>
      </Row>
    );
  };

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.drawer,
          isDrawerOpen && styles.drawerShadow,
          {
            transform: [{ translateX: translateX }]
          }
        ]}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          style={[styles.headerRow, !isDrawerOpen && styles.headerRowRounded]}
        >
          <Pressable
            testID="filter-drawer-button"
            eventTargetName="Filter Drawer Button"
            eventEntityType="filter-drawer-button"
            onPress={toggleDrawer}
          >
            <View style={styles.headerButton}>
              <ContentArea variant="compact" size="medium">
                <ButtonLayout
                  icon={isDrawerOpen ? "chevron-right" : "chevron-left"}
                />
              </ContentArea>
            </View>
          </Pressable>
          {filterCount > 0 && (
            <Button
              testID="reset-filters"
              outline
              size="small"
              label="Reset"
              icon="redo"
              onPress={onResetFilters}
            />
          )}
        </Row>
        {isDrawerOpen && (
          <View style={styles.contentContainer}>
            <ContentArea>
              <ScrollView testID="filter-drawer-content">{children}</ScrollView>
            </ContentArea>
          </View>
        )}
      </Animated.View>
    </View>
  );
}
