import type { DateTime } from "luxon";
import React from "react";
import { Platform } from "react-native";
import { ListRow } from "../atoms";
import { MaskedTextInput } from "../molecules";
import { Icon, Row, Text } from "../quarks";
import Column from "../quarks/Column";
import { useStyles } from "../style";
import Picker from "./Picker";

const OPTIONS_AM_PM = ["AM", "PM"];

interface Props {
  label: string;
  time?: string;
  date?: DateTime;
  availableDates?: DateTime[];
  ampm?: string;
  zIndex?: number;
  onChangeTime?: (time: string) => void;
  onChangeAmpm?: (ampm: string) => void;
  onChangeDate?: (date: DateTime) => void;
  error?: string | null;
  inputTestId: string;
}

const toValue = (date?: DateTime) => {
  const result = date ? date.toFormat("ccc D") : "";
  return result;
};

const DateTimeInputRow = ({
  label,
  time,
  ampm,
  date,
  availableDates,
  zIndex = 1,
  onChangeTime,
  onChangeAmpm,
  onChangeDate,
  error,
  inputTestId
}: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    input: { width: 64 },
    bottomContent: { marginTop: -getUnits(1), paddingBottom: getUnits(3) }
  }));

  const showDates = !!availableDates;
  const handleChangeDate = (newDate: string | null) => {
    const date = availableDates?.find((d) => toValue(d) === newDate);
    if (date) onChangeDate?.(date);
  };

  const hmNode = (
    <Row gap="compact" fill={showDates}>
      <MaskedTextInput
        fill={showDates}
        containerStyle={styles.input}
        testID={`${inputTestId}-time-picker-hm-input`}
        value={time}
        mask="time"
        placeholder="-- : --"
        onChangeText={onChangeTime}
        keyboardType={Platform.OS === "web" ? "default" : "number-pad"}
      />
      <Picker
        fill={showDates}
        containerStyle={styles.input}
        eventTargetName="Input AM/PM Picker"
        testID={`${inputTestId}-time-picker-ampm-input`}
        value={ampm}
        placeholder="--"
        options={OPTIONS_AM_PM}
        onChange={(val) => val && onChangeAmpm?.(val)}
      />
    </Row>
  );

  return (
    <Column zIndex={zIndex + 2}>
      <ListRow
        left={<Icon name="clock" size="tiny" color="primary" variant="solid" />}
        label={label}
        right={showDates ? null : hmNode}
        rightSpacing="none"
      />
      {showDates && (
        <Row gap="standard" style={styles.bottomContent}>
          <Picker
            fill
            eventTargetName="Input Date Picker"
            testID={`${inputTestId}-date-picker-input`}
            value={toValue(date)}
            options={availableDates.map(toValue)}
            onChange={handleChangeDate}
            placeholder="Select Date"
          />
          {hmNode}
        </Row>
      )}
      {!!error && (
        <Text
          color="danger"
          style={styles.bottomContent}
          align="right"
          variant="note"
          eventTargetName="DateTime Input Error Text"
        >
          {error}{" "}
        </Text>
      )}
    </Column>
  );
};

export default DateTimeInputRow;
