import React, { type ReactNode } from "react";
import { Pressable } from "react-native";
import { Icon, type IconName, Row } from "../../quarks";

type Props = {
  editable?: boolean;
  icon?: IconName;
  onEdit: () => void;
  children: ReactNode;
};

export const EditableCell = ({
  editable = true,
  icon = "edit",
  onEdit,
  children
}: Props) => {
  if (!editable) return <>{children}</>;
  return (
    <Pressable onPress={onEdit}>
      <Row gap="compact">
        {children}
        <Icon variant="solid" size="tiny" name={icon} />
      </Row>
    </Pressable>
  );
};
