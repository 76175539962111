import React from "react";
import { useMatchesViewport } from "../../style";
import DesktopFilter from "./DesktopLayout";
import MobileFilter from "./MobileLayout";

interface Props {
  children: React.ReactNode;
  filterCount: number;
  onResetFilters: () => void;
  submitLabel: string;
  onSubmit: () => void;
}

export default function FilterDrawer(props: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  return isSm ? <MobileFilter {...props} /> : <DesktopFilter {...props} />;
}
