import React, { type ReactNode } from "react";
import { View } from "react-native";
import { IsConstrainedProvider, useConstraint } from "../../atoms";
import {
  type BreakpointName,
  useMatchesViewport,
  useStyles
} from "../../style";

type Props = {
  constraint?: BreakpointName | "none";
  children?: ReactNode;
};
export function StepCenter({ constraint = "xsmall", children }: Props) {
  const [constraintStyle, isConstrained] = useConstraint(constraint);
  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const styles = useStyles(() => ({
    // Icon+Gap left spacing from StepWithTitle component
    paddingFix: { left: -25 }
  }));

  return (
    <IsConstrainedProvider value={isConstrained}>
      <View style={[constraintStyle, isLg && styles.paddingFix]}>
        {children}
      </View>
    </IsConstrainedProvider>
  );
}
