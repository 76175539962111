import React from "react";
import {} from "react-native";
import {} from "../../atoms";
import {
  Button,
  ContentArea,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../atoms";
import { showModal } from "../../organisms";
import { KeyboardAvoidingView } from "../../quarks";

interface WrapperProps {
  children: React.ReactNode;
  filterCount: number;
  onResetFilters: () => void;
  submitLabel: string;
  onSubmit: () => void;
}

interface FilterProps extends WrapperProps {
  onClose: () => void;
}

function FilterModal({
  children,
  filterCount,
  onResetFilters,
  submitLabel,
  onSubmit,
  onClose
}: FilterProps) {
  return (
    <>
      <ModalHeader
        title="Filters"
        icon="filter"
        titleAction={
          filterCount > 0 && (
            <Button
              testID="reset-filters-btn"
              outline
              size="small"
              icon="undo"
              label="Reset Filters"
              onPress={onResetFilters}
            />
          )
        }
      />
      <ModalBody scrollable enableResetScrollPosition={false}>
        <KeyboardAvoidingView>
          <ContentArea>{children}</ContentArea>
        </KeyboardAvoidingView>
      </ModalBody>
      <ModalFooter>
        <Button
          onPress={() => {
            onSubmit?.();
            onClose();
          }}
          label={submitLabel}
          disabled={filterCount === 0}
          testID="filter-cta-btn"
        />
      </ModalFooter>
    </>
  );
}

export default function MobileFilter(props: WrapperProps) {
  return (
    <Button
      testID="filter-drawer-button-mobile"
      size="small"
      outline
      label="Filters"
      variant="clear"
      icon="filter"
      onPress={() =>
        showModal({
          testID: "filter-modal",
          eventContext: "Filter Modal",
          useModalBody: false,
          children: (onClose) => <FilterModal {...props} onClose={onClose} />,
          variant: "full-shadow",
          fixedHeight: true
        })
      }
    />
  );
}
